export default {
  text: '#111111',
  text2: '#111111',
  primary: '#062C63',
  secondary: '#062C63',
  background: 'white',
  backgroundPrimary: '#f5f5f5',
  backgroundSecondary: '#f5f5f5',
  backgroundTertiary: '#f5f5f5',
  light: '#FFF',
  dark: '#000000'
}
